@use '_modules' as *;

body#shop, body#powder-metals{

  background-color:black; 

  // --background-theme-one : rgba(black, 0.4);
  // --background-theme-two : black;

  > background-image, > background-video {
    --row-background: 1 / span 2;
    height:initial !important;
    @include break-in(940) {
      height: 100vh;
    }
  }

  header.header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom:$spacer2; 
    position:relative; 
    > * { max-width:900px; text-shadow: 1px 1px 4px black; }
    h1 { @include heading-h3; color:$red; }
    h2 { @include heading-h1; }
    .produces-in-the-uk {
      height:100px;
      @include break-out(1380) {
        position:absolute;
        left:calc(50% + 450px + 100px); 
        top:calc(#{$spacer} + 1em);  
      }
      @include break-in(1380) {
        margin-top:$gutter; 
        margin-bottom:$gutter-;
      }
    }
  }  

  main {

    .content-block {

      // &[data-blocks*="articleList"] {
      //   article-list {
      //     --article-min-width : 400px; 
      //     max-width:$break-large;
      //     width:100%; 
      //     margin:0 auto; 
      //     @include break-out(medium) { 
      //       grid-template-columns: repeat(3, 1fr);
      //     }
   
      //     .article {
      //       .container {
      //         position: relative;
      //         border:initial;
      //         justify-content: center;
      //         display:grid;
      //         text-align: center;
      //         padding-top:160px; 
      //         .heading { 
      //           color:white; 
      //           text-shadow: 1px 1px 4px black; 
      //         }
      //         span.button { 
      //           --padding: 3px 16px;
      //           align-self: center;
      //           width:fit-content;
      //           margin-left:auto; 
      //           margin-right:auto; 
      //           font-size:font-size(11);
      //           svg { display:none; }
      //         }
      //         .heading, .button { z-index:1; width:100%;  }
      //         picture.image {
      //           // width: 100%;
      //           position: absolute;
      //           z-index:0; 
      //           margin:0; 
      //           height:100%;
      //           transition:transform $transition; 
      //         }
      //         @include hover {
      //           picture.image {
      //             transform:scale(1.1);
      //           }
      //           span.button {
      //             background-color:white; 
      //             color:$red; 
      //             border-color:$red; 
      //           }
      //         }
      //       }
      //     }
      //     @include break-in(small) { 
      //       --article-min-width : 100%; 
      //       .article .container { padding-top:100px; }
      //     }
      //   }
      // }

      &:first-of-type{
        padding-top: var(--spacer1);
      }

      &.dark {
        box-shadow:initial;
        padding-bottom:$spacer2; 
        &::before {
          z-index:0; 
          background-image: linear-gradient(transparent, black 200px);
        }
        > * { z-index:1; }
        > .image.static {
          grid-row : span 3;
          border-radius: initial;
          height:100%; 
          width: 130%;
          right: 30%;
          z-index:0; 
          img {
            height:100%; 
          }
          &::after {
            all: initial;
            content:"";
            position:absolute;
            top:0; 
            left:0; 
            width: 100%;
            height: 100%;
            background-image:linear-gradient(black, transparent, transparent, black),
            linear-gradient(to right, black, transparent, transparent, black);
          }
        }
        @include break-in(large) {
          padding-bottom:0; 
          > .image.static {
            grid-row: 5;
            grid-column: 1 / -1;
            max-height: 400px;
            @include break-in(700) { 
              grid-row:8;
              max-height: 200px;
            }
            &::after {
              background-image:linear-gradient(black, rgba(black, 0.5)),
              linear-gradient(to right, black, transparent, transparent, black);
            }
          }
        }
      }
    }
  }
}

body.shop {

  a.logo{
    &:after{
      display: none;
    }
  }

  li.cart{
    display: none;
  }

  footer#footer {
    --primary:black;
  }

  navigation-bar {
    &:not(.invert):not(.hide-background) {
      background-color:black; ;
    }
    nav main-menu > ul > li.top-level-link:nth-last-child(4)::before { content : initial; } 
  }

  #logo-scroller {
    .heading-h4 { color:black; }
  }

  #breadcrumbs-bar {
    background-color:black;
    &::before { border-top:1px solid $black; }
    ul { 
      width: 100%; 
      @at-root #breadcrumbs-bar ul li.cart {
        margin-left:auto;
        align-self: center;
        a {
          height: initial;
          padding-top:0.5em; 
          text-decoration: none;
          padding-bottom:0.5em; 
          svg {
            width:1em;
            margin-left:0.5em;
            height:1em;
            fill:white; 
          }
            @include hover {
              background-color:$white;
              svg {
                fill:$red; 
              }
            }
        }
      }
    }
  }
}

body.casestudies .header {
  padding-top:$spacer2; 
  padding-bottom:$spacer2; 
}

.product-nav {
  box-shadow: initial !important;
  display:grid;
  grid-gap:$gutter;
  // max-width:1400px; 
  width: 100%; ;
  margin:0 auto $spacer auto; 
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  article {
    // border:1px solid $black; 
    border-radius: $border-radius;
    overflow: hidden;
    background-color:#1c1c1c; 

    > div {
      display:grid;
      align-items: center;
      > * { grid-row:1; grid-column: 1;} 
      h3 { 
        position: relative;
        z-index:1; 
        width: 100%;
        padding:$gutter;
        text-align: center;
        color:white; 
        font-size:font-size(30);
        // margin-top:110px; 
        text-shadow: 1px 1px 4px black; 
      }
      picture.image {
        height:220px; 
        width: 100%;
        opacity:0.6;
      }
    }
    ul { 
      margin-top:$gutter0;
      margin-bottom:$gutter0;
      padding-left:$gutter;
      padding-right:$gutter;
      li {
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          padding:$gap0 0; 
          font-size:font-size(14);
          color:white; 
          svg { 
            width: 1em;
            height: 1em;
            margin-right:0.8em;
            --stroke : white; 
            --fill : #{$red}; 
          }
          @include hover {
            color:$red; 
          }
        }
        + li a { 
          border-top:1px dashed rgba(white, 0.2); 

        }
      }
    }
 
  }
}

shop-navigation {
  display:grid; 
  padding:0; 
  margin-bottom:$spacer; 
  box-shadow : initial !important;
  grid-gap: $gutter;
  grid-auto-rows: 1fr;
  article {
    position:relative; 
    padding:$gutter; 
    color:white; 
    background-image: linear-gradient(to bottom, transparent, rgba(black, 0.1) 33.333%, rgba(black, 0.5) 50%, rgba(black, 0.1) 66.666%, transparent) !important;
    border-radius: $border-radius;
    .heading { 
      color:inherit; 
      color:white;
      position: absolute; 
      text-align: center;
      line-height: 100%;
      width: 100%;
      height: fit-content;
      top: calc(50% - 20px);
      left: 0;
    }
    p { 
      font-size:0.9em; 
      color:white; 

    }

    .heading, .content{
      transition: all $transition;
    }

    .content{
      opacity: 0;
      transform: scale(0.95);
      margin-top: calc(40px + #{$gap});
    }

    .button { margin-top:$gap;}
    .image {
      position:absolute; 
      z-index:-1;
      left:0;
      top:0;
      width:100%; 
      height:100%; 
      opacity:0.8; 
      transition: all $transition;
      filter: grayscale(0);
      border-radius: $border-radius;
    }
    ul {
      margin-top:$gap; 
      li {
        a {
          text-decoration: none;
          color:$red; 

          svg {
            margin-left:0.4em; 
            width:0.6em; 
            height:0.6em; 
          }
          @include hover {
            color:white; 
          }
        }
      }
    }
    @include hover() {
      .image { 
        opacity: 0.2;
        filter: grayscale(0.8);
      }
      .heading{
        top: 40px;
      }
      .content{
        opacity: 1;
        transform: scale(1);
      }
    }

    &.custom{
      .content{
        a {
          text-decoration: none;
          color:$red; 

          svg {
            margin-left:0.4em; 
            width:0.6em; 
            height:0.6em; 
          }
          @include hover {
            color:white; 
          }
        }
      }
    }
  }

  &::before {
    content:"";
    @include position-expanded;
    outline:2px solid black;
    pointer-events: none;
    z-index: 1;
  }

  @include break-out(1300) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include break-between(700, 1300) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include break-in(1000) { 
    grid-auto-rows: auto;
    article{
      display: flex;
      flex-direction: column;
      .heading, .content, .image{
        position: relative;
      }
      
      .heading{
        top: 0;
        margin-bottom: $gap;
        order: 1;
      }
      .content{
        transform: scale(1);
        opacity: 1;
        margin-top: $gap;
        order: 3;
      }
      .image { 
        order: 2;
        z-index: 1;
        display: block;
        height: 100px;
      }
    }
  }
}

body#contact.shop #breadcrumbs-bar:before {
  border-top:1px solid $black;
}

header .tailwind .container {
  nav {
    a { font-size:1.4em; margin-bottom:$gap0; 
      &[class*="focus"]:first { color:$red; }
    }
  }
  .bar { 
    background-color : #{$red};
  }
}

header form.variants{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $gap;
  order: 3;
  margin-top: $gap2;
  select, .qty-container, .price-container{
    width: 100%;
  }
  select{
    
    border-radius: 50px;
  }

  select, input{
    &:focus{
      outline: none;
    }
  }
  .qty-container{
    display: flex;
    input:not([type=checkbox]):not([type=radio]){
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      min-height: 50px;
      width: 50%;
      & + p{
        width: 50%;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border: 1px solid #6A6A6A;
        display: inline-block;
        padding: $gap 0 $gap 8px;
        line-height: 1.15;
        background-color: $white;
        border-left: 0px;
      }
    }
  }
  .price-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    &.signed-in{
      border-radius: 50px;
      border: 1px solid #6A6A6A;
      padding: $gap 8px;
      line-height: 1.15;
    }
    .on-sale{
      color: $red;
    }
    a{
      font-size: 14px;
      min-height: 50px;
      margin-left: auto;
    }
  }
  button[type="submit"]{
    margin-left: auto;
  }

  @media(min-width: 900px){
    select, .qty-container{
      width: 30%;
      flex-grow: 1;
      input:not([type=checkbox]):not([type=radio]){
        width: 45%;
      }
    }
    .price-container{
      width: auto;
      min-width: 20%;
    }
  }
}
// Product page
body.shop.product {

  .content-block:first-of-type {
    padding-bottom:0;
  }

  main > section.dark.blowout {
    padding-top:0 !important;
    box-shadow: none !important;
  }
  section > tabbed-content {
    &[data-style="2"] {
      background-color:black; 
      position: relative;
      @include background-blowout() { z-index:0; };
      nav[role="tablist"] {
        justify-content: flex-start;
        background-color:$grey3;
        padding:0 $gap;
        position: relative;
        @include background-blowout() { z-index:0; };
        button {
          background-color:inherit;
          margin:0; 
          border-radius: initial;
          border:initial;
          position: relative;
          text-transform: uppercase;
          padding:2em $gap; 
          font-size:font-size(16);
          &[aria-selected="true"] {
            &:after {
              content:"";
              width:calc(100% - #{$gap2});
              height:3px; 
              background-color:$red; 
              position: absolute;
              bottom:0; 
              left:$gap; 
            }
          }
        }
      }
      details {
        &#properties-tab { 
          .tab-content { grid-template-columns: repeat(auto-fit, minmax(600px, 1fr)); 
            .text:last-child { 
              grid-column-end: span 2;
            }
          }
        }
        &#overview-tab { 
          .tab-content { grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); }
        }
        .tab-content {
          background:initial;
          table-container {
            margin-bottom: $gap; 
          }
          @include break-out(medium) {
            grid-gap:$gutter;
            align-items: flex-start;
          }
        }
      }
    }
  }
  table-container, .dark.content-block .tab-content table.table {
    // width:fit-content;
  }

  .dark.content-block .tab-content {
    .heading-h3 { color:white; }
  }

  tabbed-content {

    form {
      max-width: 900px;
      color:white; 
      h2 { 
        color:white; 
        svg.email { fill:white; display:none;  }
      }
    }

    @media (min-width: 980px) and (max-width: 1300px), (max-width: 700px) {
      &:not(.markets).allow-breakpoint-restyling  {
        padding-top:$gutter; 
        padding-bottom:$gutter; 
        .tab-content { 
          border:initial !important;
        }

        details {
          summary { --background-colour:#{rgba(white, 0.2)}; }
          &[open] {
            summary {
              --background-colour:#{rgba(white, 0.25)};
            }
          }
        }
      }
    }
  }

  .product-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row:var(--row-header);  
    grid-column : var(--column-left);
    max-width:var(--main-max-width);
    padding-bottom:$spacer;  
    margin-right:calc((-1 * (var(--sidebar-width) / 2)) + #{$gutter0});
    position: relative;
    padding-top: calc(400px + #{$gutter} + var(--spacer1));
    picture.image {
      opacity: 0;
      width:100%; 
      min-height:400px; 
      border:1px solid $grey4;
      border-radius: calc(#{$border-radius} * 2);
      margin-bottom:$gutter; 
      position: absolute;
      top: var(--spacer1);
      left: 0;
      transition: opacity .3s ease-in-out;
      &.active{
        opacity: 1;
      }
    }
    nav {
      display:flex;
      justify-content: center;
      border-radius: 50px;
      align-self: center;
      position: relative;
      width:fit-content;
      margin-bottom:$gutter; 
      background-color:$grey3;
      &:after{
        background-color:$blue; 
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        border-radius: 50px;
        transition: all .2s ease-in-out;
      }
      &[data-index="0"]{
        &:after{
          width: 35%;
          left: 0;
        }
      }

      &[data-index="1"]{
        &:after{
          width: 30%;
          left: 34%;
        }
      }

      &[data-index="2"]{
        &:after{
          width: 38%;
          left: 62%;
        }
      }

      button {
        position: relative;
        text-align: center;
        border:none !important; 
        text-transform: uppercase;
        font-size:font-size(13);
        border-radius: inherit;
        z-index:1;
        transition: color $transition, background-color $transition;
        svg { display:none; }
        padding:$gap0 $gap; 
        &[aria-selected="true"], &.active, &:active:hover {
          color:white !important; 
        }
  
        &:not([aria-selected="true"]) {
          @include hover {
            color:$red;
          }
        }

      }
      mark {
        display:none;
      //   @include mark-reset();
      //   position: absolute;
      //   top:0; left:0; 
      //   height:100%;
      //   background-color: $blue;
      //   width: 50%;
      //   border-radius: inherit;
      //   pointer-events: none;
      //   opacity: 0;
      //   transition: opacity $transition;
      //   &.active { 
      //     opacity: 1;
      //   }
      }
    }

    .certs-container{
      display: flex;
      .certs{ 
        margin: auto $gap;
        img { max-height:80px; width: 100px; }
      }
    }
  }
  .header {
    grid-column : var(--column-right);
    margin-left:calc((-1 * (var(--sidebar-width) / 2)) + #{$gutter0});
    display:flex;
    flex-direction: column; 
    h1 { order:2; }
    h2 { order:1; margin-top:0; }
    .introduction { 
      order:3; 
      margin-top:$gap; 
      color:$red;  
      font-size:font-size(20);
    }
    .product-content { 
      order:4; 
      margin-top:$gutter; 
      p + p, .cta{
        margin-top: $gap;
      }
    }
  }

  @include break-in(small-medium) {

    .product-details {
      grid-row:3;  
      margin-right:initial;
    }
    .header { 
      margin-left:initial;
    }
    .product-details, .header {
      grid-column : var(--column-full);
    }
    main { 
      grid-row:4;  
    }

  }
  
  section.variants {

    form{
      & > div{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        & > div{
          width: 100%;
          justify-content: flex-start;
          &:nth-of-type(2n){
            justify-content: flex-end;
          }
          &:last-of-type{
            margin-left: auto;
          }
        }
      }
      .flex{
        margin-bottom: $gap2;
        position: relative;
        input{
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          width: 45%;
          height: 100%;
          min-height: 50px;
        }
        p{
          height: 100%;
          width: 50%;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          border: 1px solid #6A6A6A;
          display: inline-block;
          padding: $gap 0 $gap 8px;
          line-height: 1.15;
          background-color: $white;
          margin-left: -6px;
        }
        div:nth-of-type(2){
          position: relative;
          max-width: 200px;

        }
      }

      button[type='submit']{
        background-color: $red;
        border-radius: 50px;
        transition: $transition;
        margin-left: auto;
        &:hover{
          background-color: darken(#DF3035, 10);
        }
      }
    }

    @media(min-width: $break-large){
      form{
        & > div{
          & > div{
            width: 50%;
          }
        }
      }
    }
    
  }

  
}

#paymentForm{
  input{
    &[type="text"], &[type="tel"]{
      margin-bottom:$gap;
      width: 100%;
    }

    &[type="checkbox"]{
      margin: 0 0.75em;
    }
  }
}

body.market-entry, body#markets {
  // --row-background: 3;

  h1.heading{
    color: white
  }
  h2.sub-heading {
    @include heading-h3;
    max-width: 1080px;
    text-align: center;
    color:white;  
  }

  #breadcrumbs-bar, footer#footer{
    background-color: black;
  }

  tabbed-content{
    details{
      summary{
        --background-colour: black;
      }
    }
  }
}

body.powder-metals side-bar service-centre-finder {
  .heading-h3 { color:white; }
}

body#cart{
  .js-address-country{
    border: 1px solid #6A6A6A;
    border-radius: var(--border-radius, 6px);
    min-height: 50px;
  }
}