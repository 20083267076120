////////////////////////////////////////////////////////////////////////////////
// Transitions
////////////////////////////////////////////////////////////////////////////////

@use "helpers/ease";

$duration : 0.2s;
$ease     : ease.$in-out;
$delay    : null;

$transition : var(--transition-duration, $duration) var(--transition-ease, $ease) var(--transition-delay, $delay);