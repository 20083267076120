body#shop, body#powder-metals {
  background-color: black;
}
body#shop > background-image, body#shop > background-video, body#powder-metals > background-image, body#powder-metals > background-video {
  --row-background: 1 / span 2;
  height: initial !important;
}
@media (max-width: 940px) {
  body#shop > background-image, body#shop > background-video, body#powder-metals > background-image, body#powder-metals > background-video {
    height: 100vh;
  }
}
body#shop header.header, body#powder-metals header.header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: var(--spacer2);
  position: relative;
}
body#shop header.header > *, body#powder-metals header.header > * {
  max-width: 900px;
  text-shadow: 1px 1px 4px black;
}
body#shop header.header h1, body#powder-metals header.header h1 {
  font-size: 1.5625em;
  line-height: 1.6;
  color: #DF3035;
}
body#shop header.header h2, body#powder-metals header.header h2 {
  font-size: 3.8125em;
  font-size: clamp(2.5em, 5vw, 3.8125em);
  line-height: 1.2;
}
body#shop header.header .produces-in-the-uk, body#powder-metals header.header .produces-in-the-uk {
  height: 100px;
}
@media (min-width: 1381px) {
  body#shop header.header .produces-in-the-uk, body#powder-metals header.header .produces-in-the-uk {
    position: absolute;
    left: calc(50% + 450px + 100px);
    top: calc(var(--spacer1) + 1em);
  }
}
@media (max-width: 1380px) {
  body#shop header.header .produces-in-the-uk, body#powder-metals header.header .produces-in-the-uk {
    margin-top: var(--gutter1);
    margin-bottom: var(--gutter1-);
  }
}
body#shop main .content-block:first-of-type, body#powder-metals main .content-block:first-of-type {
  padding-top: var(--spacer1);
}
body#shop main .content-block.dark, body#powder-metals main .content-block.dark {
  box-shadow: initial;
  padding-bottom: var(--spacer2);
}
body#shop main .content-block.dark::before, body#powder-metals main .content-block.dark::before {
  z-index: 0;
  background-image: linear-gradient(transparent, black 200px);
}
body#shop main .content-block.dark > *, body#powder-metals main .content-block.dark > * {
  z-index: 1;
}
body#shop main .content-block.dark > .image.static, body#powder-metals main .content-block.dark > .image.static {
  grid-row: span 3;
  border-radius: initial;
  height: 100%;
  width: 130%;
  right: 30%;
  z-index: 0;
}
body#shop main .content-block.dark > .image.static img, body#powder-metals main .content-block.dark > .image.static img {
  height: 100%;
}
body#shop main .content-block.dark > .image.static::after, body#powder-metals main .content-block.dark > .image.static::after {
  all: initial;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(black, transparent, transparent, black), linear-gradient(to right, black, transparent, transparent, black);
}
@media (max-width: 1400px) {
  body#shop main .content-block.dark, body#powder-metals main .content-block.dark {
    padding-bottom: 0;
  }
  body#shop main .content-block.dark > .image.static, body#powder-metals main .content-block.dark > .image.static {
    grid-row: 5;
    grid-column: 1/-1;
    max-height: 400px;
  }
}
@media (max-width: 1400px) and (max-width: 700px) {
  body#shop main .content-block.dark > .image.static, body#powder-metals main .content-block.dark > .image.static {
    grid-row: 8;
    max-height: 200px;
  }
}
@media (max-width: 1400px) {
  body#shop main .content-block.dark > .image.static::after, body#powder-metals main .content-block.dark > .image.static::after {
    background-image: linear-gradient(black, rgba(0, 0, 0, 0.5)), linear-gradient(to right, black, transparent, transparent, black);
  }
}

body.shop a.logo:after {
  display: none;
}
body.shop li.cart {
  display: none;
}
body.shop footer#footer {
  --primary:black;
}
body.shop navigation-bar:not(.invert):not(.hide-background) {
  background-color: black;
}
body.shop navigation-bar nav main-menu > ul > li.top-level-link:nth-last-child(4)::before {
  content: initial;
}
body.shop #logo-scroller .heading-h4 {
  color: black;
}
body.shop #breadcrumbs-bar {
  background-color: black;
}
body.shop #breadcrumbs-bar::before {
  border-top: 1px solid #1E1A1A;
}
body.shop #breadcrumbs-bar ul {
  width: 100%;
}
#breadcrumbs-bar ul li.cart {
  margin-left: auto;
  align-self: center;
}
#breadcrumbs-bar ul li.cart a {
  height: initial;
  padding-top: 0.5em;
  text-decoration: none;
  padding-bottom: 0.5em;
}
#breadcrumbs-bar ul li.cart a svg {
  width: 1em;
  margin-left: 0.5em;
  height: 1em;
  fill: white;
}
@media (hover: hover) {
  #breadcrumbs-bar ul li.cart a:hover {
    background-color: #FFFFFF;
  }
  #breadcrumbs-bar ul li.cart a:hover svg {
    fill: #DF3035;
  }
}

body.casestudies .header {
  padding-top: var(--spacer2);
  padding-bottom: var(--spacer2);
}

.product-nav {
  box-shadow: initial !important;
  display: grid;
  grid-gap: var(--gutter1);
  width: 100%;
  margin: 0 auto var(--spacer1) auto;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
}
.product-nav article {
  border-radius: var(--border-radius, 6px);
  overflow: hidden;
  background-color: #1c1c1c;
}
.product-nav article > div {
  display: grid;
  align-items: center;
}
.product-nav article > div > * {
  grid-row: 1;
  grid-column: 1;
}
.product-nav article > div h3 {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: var(--gutter1);
  text-align: center;
  color: white;
  font-size: 1.875em;
  text-shadow: 1px 1px 4px black;
}
.product-nav article > div picture.image {
  height: 220px;
  width: 100%;
  opacity: 0.6;
}
.product-nav article ul {
  margin-top: var(--gutter0);
  margin-bottom: var(--gutter0);
  padding-left: var(--gutter1);
  padding-right: var(--gutter1);
}
.product-nav article ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: calc(var(--gap) * 0.5) 0;
  font-size: 0.875em;
  color: white;
}
.product-nav article ul li a svg {
  width: 1em;
  height: 1em;
  margin-right: 0.8em;
  --stroke: white;
  --fill: #DF3035;
}
@media (hover: hover) {
  .product-nav article ul li a:hover {
    color: #DF3035;
  }
}
.product-nav article ul li + li a {
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
}

shop-navigation {
  display: grid;
  padding: 0;
  margin-bottom: var(--spacer1);
  box-shadow: initial !important;
  grid-gap: var(--gutter1);
  grid-auto-rows: 1fr;
}
shop-navigation article {
  position: relative;
  padding: var(--gutter1);
  color: white;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1) 33.333%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.1) 66.666%, transparent) !important;
  border-radius: var(--border-radius, 6px);
}
shop-navigation article .heading {
  color: inherit;
  color: white;
  position: absolute;
  text-align: center;
  line-height: 100%;
  width: 100%;
  height: fit-content;
  top: calc(50% - 20px);
  left: 0;
}
shop-navigation article p {
  font-size: 0.9em;
  color: white;
}
shop-navigation article .heading, shop-navigation article .content {
  transition: all var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
shop-navigation article .content {
  opacity: 0;
  transform: scale(0.95);
  margin-top: calc(40px + var(--gap));
}
shop-navigation article .button {
  margin-top: var(--gap);
}
shop-navigation article .image {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  transition: all var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  filter: grayscale(0);
  border-radius: var(--border-radius, 6px);
}
shop-navigation article ul {
  margin-top: var(--gap);
}
shop-navigation article ul li a {
  text-decoration: none;
  color: #DF3035;
}
shop-navigation article ul li a svg {
  margin-left: 0.4em;
  width: 0.6em;
  height: 0.6em;
}
@media (hover: hover) {
  shop-navigation article ul li a:hover {
    color: white;
  }
}
@media (hover: hover) {
  shop-navigation article:hover .image {
    opacity: 0.2;
    filter: grayscale(0.8);
  }
  shop-navigation article:hover .heading {
    top: 40px;
  }
  shop-navigation article:hover .content {
    opacity: 1;
    transform: scale(1);
  }
}
shop-navigation article.custom .content a {
  text-decoration: none;
  color: #DF3035;
}
shop-navigation article.custom .content a svg {
  margin-left: 0.4em;
  width: 0.6em;
  height: 0.6em;
}
@media (hover: hover) {
  shop-navigation article.custom .content a:hover {
    color: white;
  }
}
shop-navigation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 2px solid black;
  pointer-events: none;
  z-index: 1;
}
@media (min-width: 1301px) {
  shop-navigation {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 700px) and (max-width: 1300px) {
  shop-navigation {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1000px) {
  shop-navigation {
    grid-auto-rows: auto;
  }
  shop-navigation article {
    display: flex;
    flex-direction: column;
  }
  shop-navigation article .heading, shop-navigation article .content, shop-navigation article .image {
    position: relative;
  }
  shop-navigation article .heading {
    top: 0;
    margin-bottom: var(--gap);
    order: 1;
  }
  shop-navigation article .content {
    transform: scale(1);
    opacity: 1;
    margin-top: var(--gap);
    order: 3;
  }
  shop-navigation article .image {
    order: 2;
    z-index: 1;
    display: block;
    height: 100px;
  }
}

body#contact.shop #breadcrumbs-bar:before {
  border-top: 1px solid #1E1A1A;
}

header .tailwind .container nav a {
  font-size: 1.4em;
  margin-bottom: calc(var(--gap) * 0.5);
}
header .tailwind .container nav a[class*=focus]:first {
  color: #DF3035;
}
header .tailwind .container .bar {
  background-color: #DF3035;
}

header form.variants {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--gap);
  order: 3;
  margin-top: calc(var(--gap) * 2);
}
header form.variants select, header form.variants .qty-container, header form.variants .price-container {
  width: 100%;
}
header form.variants select {
  border-radius: 50px;
}
header form.variants select:focus, header form.variants input:focus {
  outline: none;
}
header form.variants .qty-container {
  display: flex;
}
header form.variants .qty-container input:not([type=checkbox]):not([type=radio]) {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 50px;
  width: 50%;
}
header form.variants .qty-container input:not([type=checkbox]):not([type=radio]) + p {
  width: 50%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: 1px solid #6A6A6A;
  display: inline-block;
  padding: var(--gap) 0 var(--gap) 8px;
  line-height: 1.15;
  background-color: #FFFFFF;
  border-left: 0px;
}
header form.variants .price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
header form.variants .price-container.signed-in {
  border-radius: 50px;
  border: 1px solid #6A6A6A;
  padding: var(--gap) 8px;
  line-height: 1.15;
}
header form.variants .price-container .on-sale {
  color: #DF3035;
}
header form.variants .price-container a {
  font-size: 14px;
  min-height: 50px;
  margin-left: auto;
}
header form.variants button[type=submit] {
  margin-left: auto;
}
@media (min-width: 900px) {
  header form.variants select, header form.variants .qty-container {
    width: 30%;
    flex-grow: 1;
  }
  header form.variants select input:not([type=checkbox]):not([type=radio]), header form.variants .qty-container input:not([type=checkbox]):not([type=radio]) {
    width: 45%;
  }
  header form.variants .price-container {
    width: auto;
    min-width: 20%;
  }
}

body.shop.product .content-block:first-of-type {
  padding-bottom: 0;
}
body.shop.product main > section.dark.blowout {
  padding-top: 0 !important;
  box-shadow: none !important;
}
body.shop.product section > tabbed-content[data-style="2"] {
  background-color: black;
  position: relative;
}
body.shop.product section > tabbed-content[data-style="2"]:before {
  content: "";
  background-color: inherit;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(var(--translateX, -50%));
  z-index: 0;
}
body.shop.product section > tabbed-content[data-style="2"] nav[role=tablist] {
  justify-content: flex-start;
  background-color: #F4F4F4;
  padding: 0 var(--gap);
  position: relative;
}
body.shop.product section > tabbed-content[data-style="2"] nav[role=tablist]:before {
  content: "";
  background-color: inherit;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(var(--translateX, -50%));
  z-index: 0;
}
body.shop.product section > tabbed-content[data-style="2"] nav[role=tablist] button {
  background-color: inherit;
  margin: 0;
  border-radius: initial;
  border: initial;
  position: relative;
  text-transform: uppercase;
  padding: 2em var(--gap);
  font-size: 1em;
}
body.shop.product section > tabbed-content[data-style="2"] nav[role=tablist] button[aria-selected=true]:after {
  content: "";
  width: calc(100% - calc(var(--gap) * 2));
  height: 3px;
  background-color: #DF3035;
  position: absolute;
  bottom: 0;
  left: var(--gap);
}
body.shop.product section > tabbed-content[data-style="2"] details#properties-tab .tab-content {
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
}
body.shop.product section > tabbed-content[data-style="2"] details#properties-tab .tab-content .text:last-child {
  grid-column-end: span 2;
}
body.shop.product section > tabbed-content[data-style="2"] details#overview-tab .tab-content {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
body.shop.product section > tabbed-content[data-style="2"] details .tab-content {
  background: initial;
}
body.shop.product section > tabbed-content[data-style="2"] details .tab-content table-container {
  margin-bottom: var(--gap);
}
@media (min-width: 1121px) {
  body.shop.product section > tabbed-content[data-style="2"] details .tab-content {
    grid-gap: var(--gutter1);
    align-items: flex-start;
  }
}
body.shop.product .dark.content-block .tab-content .heading-h3 {
  color: white;
}
body.shop.product tabbed-content form {
  max-width: 900px;
  color: white;
}
body.shop.product tabbed-content form h2 {
  color: white;
}
body.shop.product tabbed-content form h2 svg.email {
  fill: white;
  display: none;
}
@media (min-width: 980px) and (max-width: 1300px), (max-width: 700px) {
  body.shop.product tabbed-content:not(.markets).allow-breakpoint-restyling {
    padding-top: var(--gutter1);
    padding-bottom: var(--gutter1);
  }
  body.shop.product tabbed-content:not(.markets).allow-breakpoint-restyling .tab-content {
    border: initial !important;
  }
  body.shop.product tabbed-content:not(.markets).allow-breakpoint-restyling details summary {
    --background-colour:rgba(255, 255, 255, 0.2);
  }
  body.shop.product tabbed-content:not(.markets).allow-breakpoint-restyling details[open] summary {
    --background-colour:rgba(255, 255, 255, 0.25);
  }
}
body.shop.product .product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row: var(--row-header);
  grid-column: var(--column-left);
  max-width: var(--main-max-width);
  padding-bottom: var(--spacer1);
  margin-right: calc((-1 * (var(--sidebar-width) / 2)) + var(--gutter0));
  position: relative;
  padding-top: calc(400px + var(--gutter1) + var(--spacer1));
}
body.shop.product .product-details picture.image {
  opacity: 0;
  width: 100%;
  min-height: 400px;
  border: 1px solid #dcdcdc;
  border-radius: calc(var(--border-radius, 6px) * 2);
  margin-bottom: var(--gutter1);
  position: absolute;
  top: var(--spacer1);
  left: 0;
  transition: opacity 0.3s ease-in-out;
}
body.shop.product .product-details picture.image.active {
  opacity: 1;
}
body.shop.product .product-details nav {
  display: flex;
  justify-content: center;
  border-radius: 50px;
  align-self: center;
  position: relative;
  width: fit-content;
  margin-bottom: var(--gutter1);
  background-color: #F4F4F4;
}
body.shop.product .product-details nav:after {
  background-color: #131f6b;
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}
body.shop.product .product-details nav[data-index="0"]:after {
  width: 35%;
  left: 0;
}
body.shop.product .product-details nav[data-index="1"]:after {
  width: 30%;
  left: 34%;
}
body.shop.product .product-details nav[data-index="2"]:after {
  width: 38%;
  left: 62%;
}
body.shop.product .product-details nav button {
  position: relative;
  text-align: center;
  border: none !important;
  text-transform: uppercase;
  font-size: 0.8125em;
  border-radius: inherit;
  z-index: 1;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  padding: calc(var(--gap) * 0.5) var(--gap);
}
body.shop.product .product-details nav button svg {
  display: none;
}
body.shop.product .product-details nav button[aria-selected=true], body.shop.product .product-details nav button.active, body.shop.product .product-details nav button:active:hover {
  color: white !important;
}
@media (hover: hover) {
  body.shop.product .product-details nav button:not([aria-selected=true]):hover {
    color: #DF3035;
  }
}
body.shop.product .product-details nav mark {
  display: none;
}
body.shop.product .product-details .certs-container {
  display: flex;
}
body.shop.product .product-details .certs-container .certs {
  margin: auto var(--gap);
}
body.shop.product .product-details .certs-container .certs img {
  max-height: 80px;
  width: 100px;
}
body.shop.product .header {
  grid-column: var(--column-right);
  margin-left: calc((-1 * (var(--sidebar-width) / 2)) + var(--gutter0));
  display: flex;
  flex-direction: column;
}
body.shop.product .header h1 {
  order: 2;
}
body.shop.product .header h2 {
  order: 1;
  margin-top: 0;
}
body.shop.product .header .introduction {
  order: 3;
  margin-top: var(--gap);
  color: #DF3035;
  font-size: 1.25em;
}
body.shop.product .header .product-content {
  order: 4;
  margin-top: var(--gutter1);
}
body.shop.product .header .product-content p + p, body.shop.product .header .product-content .cta {
  margin-top: var(--gap);
}
@media (max-width: 980px) {
  body.shop.product .product-details {
    grid-row: 3;
    margin-right: initial;
  }
  body.shop.product .header {
    margin-left: initial;
  }
  body.shop.product .product-details, body.shop.product .header {
    grid-column: var(--column-full);
  }
  body.shop.product main {
    grid-row: 4;
  }
}
body.shop.product section.variants form > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
body.shop.product section.variants form > div > div {
  width: 100%;
  justify-content: flex-start;
}
body.shop.product section.variants form > div > div:nth-of-type(2n) {
  justify-content: flex-end;
}
body.shop.product section.variants form > div > div:last-of-type {
  margin-left: auto;
}
body.shop.product section.variants form .flex {
  margin-bottom: calc(var(--gap) * 2);
  position: relative;
}
body.shop.product section.variants form .flex input {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 45%;
  height: 100%;
  min-height: 50px;
}
body.shop.product section.variants form .flex p {
  height: 100%;
  width: 50%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: 1px solid #6A6A6A;
  display: inline-block;
  padding: var(--gap) 0 var(--gap) 8px;
  line-height: 1.15;
  background-color: #FFFFFF;
  margin-left: -6px;
}
body.shop.product section.variants form .flex div:nth-of-type(2) {
  position: relative;
  max-width: 200px;
}
body.shop.product section.variants form button[type=submit] {
  background-color: #DF3035;
  border-radius: 50px;
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  margin-left: auto;
}
body.shop.product section.variants form button[type=submit]:hover {
  background-color: #bf1d22;
}
@media (min-width: 1400px) {
  body.shop.product section.variants form > div > div {
    width: 50%;
  }
}

#paymentForm input[type=text], #paymentForm input[type=tel] {
  margin-bottom: var(--gap);
  width: 100%;
}
#paymentForm input[type=checkbox] {
  margin: 0 0.75em;
}

body.market-entry h1.heading, body#markets h1.heading {
  color: white;
}
body.market-entry h2.sub-heading, body#markets h2.sub-heading {
  font-size: 1.5625em;
  line-height: 1.6;
  max-width: 1080px;
  text-align: center;
  color: white;
}
body.market-entry #breadcrumbs-bar, body.market-entry footer#footer, body#markets #breadcrumbs-bar, body#markets footer#footer {
  background-color: black;
}
body.market-entry tabbed-content details summary, body#markets tabbed-content details summary {
  --background-colour: black;
}

body.powder-metals side-bar service-centre-finder .heading-h3 {
  color: white;
}

body#cart .js-address-country {
  border: 1px solid #6A6A6A;
  border-radius: var(--border-radius, 6px);
  min-height: 50px;
}